/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, string, func,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trackEvent as track } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import ResponsiveImage from '../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import getTrackingLabel from '../../../../../helpers/passportHelper';
import { getFeatureFlags } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getPassportData } from '../../../../../../state/ducks/Passport/Passport-Selectors';
import { openPassportSideBar } from '../../../../../../state/ducks/Passport/Passport-Action';

const useStyles = makeStyles((theme) => ({
    root: (margins) => ({
        position: 'relative',
        display: margins?.isFoodBrand ? 'block' : 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: (margins.margin_top && margins.margin_bottom) ? `${margins.margin_top} auto ${margins.margin_bottom}` : '10px auto 10px',
        width: '100%',
        height: 'auto',
        maxWidth: margins?.isFoodBrand ? margins?.max_width : '1250px',
        [theme.breakpoints.up('sm')]: {
            '& img': {
                width: '100%',
            },
        },
        [theme.breakpoints.down(1024)]: {
            margin: '0 auto 16px',
            fontSize: '0',
        },
    }),
    banner: {
        display: 'block',
        width: '100%',
        margin: '0 auto',
        maxWidth: '100%',
    },
    bannerPosAbs: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
}));

const ZoneTenUI = ({
    data, ssrDeviceType = '', trackEvent, passportData, featureFlags, presentationFamily, passportOpenSideBar,
}) => {
    const {
        link: { href = '', title = '' } = {},
        tracking_event_category = '',
        tracking_event_action = '',
        tracking_event_label = '',
        margin_top = '',
        margin_bottom = '',
        max_width = '',
    } = data;

    const isFoodBrand = presentationFamily === 'food';

    const margins = {
        margin_top, margin_bottom, isFoodBrand, max_width,
    };
    const marginCMSOverride = (margin_top || margin_bottom) ? { margin: `${margin_top} auto ${margin_bottom}` } : {}; // need to default to class definitions
    const desktopImageUrl = data?.desktop_image?.url;
    const mobileImageUrl = data?.mobile_image?.url;
    const imageTitleDesktop = data?.desktop_image?.title;
    const imageTitleMobile = data?.mobile_image?.title;
    const isExternalLink = href?.includes('/blog')
    || href?.includes('www')
    || href?.indexOf('http://') === 0
    || href?.includes('blog')
    || href?.indexOf('https://') === 0
    || href?.includes('/articles');

    const classes = useStyles(margins);

    const tagTracker = () => {
        if (title === 'Celebrations Passport') {
            const label = getTrackingLabel({}, passportData, featureFlags['is-passport-conditional-offer-on']);
            trackEvent({
                eventCategory: 'Homepage',
                eventAction: 'Passport Click',
                eventLabel: label,
            });
        } else {
            trackEvent({
                eventCategory: tracking_event_category || '',
                eventAction: tracking_event_action || '',
                eventLabel: tracking_event_label || '',
            });
        }
    };

    const mobileBanner = () => (
        <ResponsiveImage
            className={`${classes.banner} ${data?.padding_bottom && classes.bannerPosAbs}`}
            path={mobileImageUrl}
            params={{ mobile: 'quality=100' }}
            alt={imageTitleMobile}
        />
    );

    const desktopBanner = () => (
        <ResponsiveImage
            className={`${isFoodBrand && classes.banner} ${data?.padding_bottom && classes.bannerPosAbs}`}
            path={desktopImageUrl}
            params={{ desktop: 'quality=100' }}
            alt={imageTitleDesktop}
        />
    );

    const handleClick = (event) => {
        if (title === 'Celebrations Passport'
            // VARIANT A is "Passport Enrolment Page"
            // CONTROL is "Passport Side Bar"
            && featureFlags['which-passport-sidebar-enabled-variant']?.variant === 'CONTROL') {
            event.preventDefault();
            passportOpenSideBar();
        }
        tagTracker();
    };

    const renderBanner = (banner) => {
        if (href) {
            return (
                <>
                    {isExternalLink
                        ? (
                            <a
                                className={classes.root}
                                aria-hidden="true"
                                tabIndex="-1"
                                href={href}
                                title={title}
                                style={marginCMSOverride}
                                onClick={(event) => handleClick(event)}
                                onKeyDown={(event) => event.key === 'Enter' && handleClick(event)}
                            >
                                {data?.padding_bottom && <div style={{ paddingBottom: data.padding_bottom }} />}
                                {banner()}
                            </a>
                        )
                        : (
                            <Link
                                to={href}
                                aria-hidden="true"
                                tabIndex="-1"
                                className={classes.root}
                                title={title}
                                onClick={(event) => handleClick(event)}
                                onKeyDown={(event) => event.key === 'Enter' && handleClick(event)}
                                style={marginCMSOverride}
                            >
                                {data?.padding_bottom && <div style={{ paddingBottom: data.padding_bottom }} />}
                                {banner()}
                            </Link>
                        )}
                </>
            );
        }
        return (
            <div className={classes.root}>{banner()}</div>
        );
    };
    // TODO: Refactor for cleaner code.

    return (
        <>
            <Media
                query="(min-width: 767px)"
                defaultMatches={ssrDeviceType === 'desktop'}
                render={() => renderBanner(desktopBanner)}
            />

            <Media
                query="(max-width: 767px)"
                defaultMatches={ssrDeviceType === 'mobile'}
                render={() => renderBanner(mobileBanner)}
            />
        </>
    );
};

ZoneTenUI.defaultProps = {
    featureFlags: {},
    passportData: {},
    passportOpenSideBar: func,
};

ZoneTenUI.propTypes = {
    trackEvent: func.isRequired,
    data: shape({
        desktop_image: shape({
            url: string,
            title: string,
        }),
        link: shape({
            href: string,
        }),
    }).isRequired,
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
    featureFlags: shape({
        'which-passport-sidebar-enabled-variant': shape({
            variant: string,
        }),
    }),
    passportData: shape({
        passport_messaging: shape({
            user_with_expired_passport_subscription: {},
            user_whose_passport_subscription_is_about_to_expire: {},
        }),
    }),
    passportOpenSideBar: () => {},
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    passportData: getPassportData(state),
});
const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
    passportOpenSideBar: bindActionCreators(openPassportSideBar, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ZoneTenUI);
